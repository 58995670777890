import React, { useState, useEffect } from 'react'
import { DisableBodyScroll } from '../utils'
import I18n from './UI/I18n'
import Button from './UI/Button'
import Title from './UI/Title'
import Popup from './UI/Popup'

const Customization = ({ customization = [], saved, disabled }) => {
  const [open, setOpen] = useState(false)
  const [warn, setWarn] = useState(false)
  useEffect(() => {
    const handler = event => {
      if (event.origin !== 'https://crea.wizito.com') return
      if (event.data?.event === 'saved') {
        setOpen(false)
        saved([event.data, customization[1]])
      }
      if (event.data?.event === 'exit')
        setOpen(false)
    }
    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  }, [])
  const pola = (customization || [])[0]
  const screen = (customization || [])[1]

  return (
    <>
      <div className="flex flex-col justify-between text-center w-full md:w-96 h-96 p-5 text-black bg-pink-light flex-shrink-0">
        <Title variant="extra-large" className="text-black">
          <I18n>app-redesign.customization.title</I18n>
        </Title>
        <img src="/custo.png" className="w-full mt-6 mx-auto" />
        <p className="text-base mx-auto my-3"><I18n>app-redesign.customization.description</I18n></p>
        <Button
          variant="secondary"
          disabled={disabled}
          onClickDisabled={() => setWarn(true) }
          className="mx-auto mt-3 text-black"
          onClick={() => setOpen(true)}><I18n>app-redesign.customization.button</I18n></Button>
      </div>
      {warn &&
        <Popup title="Attention" close={() => setWarn(false)} small>
          Si votre borne photo a déjà été préparée, vos modifications ne seront pas prises en compte.
          <br/>
          Vous pouvez nous contacter au 01 84 60 81 60.
          <Button variant="secondary" className="mt-4" onClick={() => { setWarn(false); setOpen(true) }}>C'est noté</Button>
        </Popup>
      }
      {open && pola &&
        <>
          <iframe src={`https://crea.wizito.com/project/${pola.id}_${pola.token}?lng=fr`} className="fixed bg-white top-0 left-0 w-screen h-screen z-125" />
          <DisableBodyScroll />
        </>
      }
    </>
  )
}

export default Customization
