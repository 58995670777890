import React, { useState, useEffect, useRef } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import moment from 'moment'
import I18n from './UI/I18n'
import Button from './UI/Button'
import Title from '../components/UI/Title'
import PayEvent from '../components/PayEvent'
import { FiEdit, FiX } from "react-icons/fi"
import Info from './UI/Info'

const inputStyle = 'border-none focus:outline-none'

const Summary = ({ locked, event, options, setCustomerInfo, payTrigger, stripePromise, upcoming, tryPromo, finalize, advance, succeeded }) => {
  const [open, setOpen] = useState(false)
  const [firstname, setFirstname] = useState(event.firstname)
  const [email, setEmail] = useState(event.email)
  const [phone, setPhone] = useState(event.phone)

  const invoices = [...(event.stripeCache.invoices || []), upcoming]
  const deliveryMethod = event.products.some(p => p.upsale && p.shipping) ? 'Delivery' : 'Pickup'
  const activeOptions = options.filter(o => {
    const invoice = invoices.find(i => i && i.lines.data.find(l => l.price.product === o.stripeCache.product.id))
    return o.active || invoice
  })

  const inputElement = useRef()

  useEffect(() => {
    if (inputElement.current)
      inputElement.current.focus()
  }, [inputElement, open])

  return (
    <div className={`z-75 flex flex-col bg-gray-100 w-full px-6 lg:px-10 lg:py-2 relative flex-shrink-0`} style={{ maxWidth: 518 }}>
      <Title variant="extra-large" className="mx-auto mt-8 mb-4">
        <div className="z-10"><I18n>app-redesign.summary.title</I18n></div>
      </Title>
      <div className="flex flex-col text-base">
        <strong>Vos informations personnelles</strong>
        {!open && (
          <div className="flex justify-between items-center">
            <div className="text-base flex justify-start items-center">
              <div className="w-full">
                <p className="capitalize w-full">❤️&nbsp;{firstname}</p>
                <p>✉️&nbsp;{email}</p>
                <p>📞&nbsp;{phone}</p>
                <Button className="mt-2" variant="small" disabled={locked} Icon={FiEdit} onClick={() => setOpen(true)}>Modifier</Button>
              </div>
            </div>
          </div>
        )}
        {open && (
          <>
            <div className="text-base flex justify-start items-center">
              <div className="w-full">
                <span className="capitalize flex justify-start items-center w-full">
                  <p>❤️ &nbsp;</p>
                  <input type="text" className={`${inputStyle} w-9/10`} ref={inputElement} value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                </span>
                <span className="flex justify-start items-center w-full">
                  <p>✉️ &nbsp;</p>
                  <input type="text" className={`${inputStyle} w-9/10`} value={email} onChange={(e) => setEmail(e.target.value)} />
                </span>
                <span className="flex justify-start items-center w-full">
                  <p>📞 &nbsp;</p>
                  <input type="text" className={`${inputStyle} w-9/10`} value={phone} onChange={(e) => setPhone(e.target.value)} />
                </span>
              </div>
            </div>
            <Button variant="small" className="mt-2" onClick={() => setCustomerInfo(firstname, email, phone).then(() => setOpen(false))}>
              <I18n>app-redesign.summary.save</I18n>
            </Button>
          </>
        )}
        <div className="text-base mt-3">
          <strong>Compris dans votre offre</strong>
          <p>📸️ <I18n>app-redesign.summary.photos</I18n></p>
          <p>🗓️️ Le weekend du {moment(event.date).format('DD/MM/YYYY')}</p>
          <p>🚛 Livraison et récupération par UPS</p>
        </div>
        {activeOptions.length > 0 &&
          <div className="text-base mt-3">
            <strong><I18n>app-redesign.summary.options</I18n></strong>
            {activeOptions.map(ao =>
              <li key={ao.id}>{ao.name}</li>
            )}
          </div>
        }
      </div>
      <div className="flex flex-col lg:flex-row items-center justify-center mt-4">
        {event.stripeCache &&
          <Elements stripe={stripePromise} options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito:wght@100;300;400;500' }] }}>
            <PayEvent
              locked={locked} event={event} upcoming={upcoming} tryPromo={tryPromo} finalize={finalize} succeeded={succeeded} payTrigger={payTrigger} advance={advance}
            />
          </Elements>
        }
      </div>
      <Info className="mt-2">
        <I18n>app-redesign.summary.contact</I18n> <br/><b><I18n>app-redesign.summary.phone</I18n></b> ou <b><I18n>app-redesign.summary.email</I18n></b>
      </Info>
    </div>
  )
}

export default Summary
