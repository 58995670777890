import React, { useState } from 'react'
import I18n from './UI/I18n'
import Button from './UI/Button'
import Title from './UI/Title'
import DeliveryOption from './DeliveryOption'
import DeliveryModal from './DeliveryModal'
import { FiLoader } from 'react-icons/fi'
import Popup from './UI/Popup'

const Delivery = (props) => {
  const { shippings, event, upcoming, products, addProduct, removeProduct, disabled } = props

  const [selected, setSelected] = useState(event.products.some(p => p.upsale && p.shipping) ? [true, false] : [false, true])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [warn, setWarn] = useState(false)

  const inv = event.stripeCache.invoices || []
  const current = upcoming || inv.find(i => i.status === 'draft') || inv.find(i => i.status === 'open')
  const deliveryMethod = event.products.some(p => p.upsale && p.shipping) ? 'Delivery' : 'Pickup'
  
  return (
    <>
      <div className="flex flex-col justify-between items-center text-center w-full md:w-96 p-5 bg-pink-light flex-shrink-0">
        <Title variant="extra-large">
          <I18n>app-redesign.delivery.title</I18n>
        </Title>
        <div className="w-full h-36 bg-cover mt-3 rounded-xl" style={{ backgroundImage: 'url("/ups.jpg")' }} />
        <p className="text-base leading-tight mt-3 mb-1"><I18n>app-redesign.delivery.description</I18n></p>
        {/* <div className="flex justify-center items-center w-full mb-8 mt-2">
            {shippings.sort((a,b) => a.upsale ? -1 : 1).map((shipping, i) => (
                <DeliveryOption 
                    key={i} event={event} products={products} shipping={shipping} loading={loading} selected={selected[i]}
                    addProduct={addProduct} removeProduct={removeProduct} setLoading={setLoading} setSelected={setSelected} 
                    onClick={() => setSelected(selected.map(s => !s))} />
            ))}
        </div> */}
        <Button
          disabled={disabled}
          variant="secondary"
          onClick={() => setOpen(true)}
          onClickDisabled={() => setWarn(true)}>
          {current?.loading || loading
              ? <FiLoader className="h-5 w-5 stroke-1 rotating box-border inline" />
              : `Je complète mon adresse`
          }
        </Button>
        <DeliveryModal {...props} open={open} setOpen={setOpen} isMarkerShown />
        {warn &&
          <Popup title="Attention" close={() => setWarn(false)} small>
            Si votre borne photo a déjà été préparée, vos modifications ne seront pas prises en compte.
            <br/>
            Vous pouvez nous contacter au 01 84 60 81 60.
            <Button variant="secondary" className="mt-4" onClick={() => { setWarn(false); setOpen(true) }}>C'est noté</Button>
          </Popup>
        }
      </div>
    </>
  )
}

export default Delivery